// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JnMthDcw4bHz1A8zHmMa{display:flex;flex-direction:row;flex-wrap:wrap;gap:16px;margin-bottom:16px}.ATiGgaXhCShzK8ycx4w2{height:1500px}@media(max-width: 768.02px){.ATiGgaXhCShzK8ycx4w2{height:3126px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sparePartItems": "JnMthDcw4bHz1A8zHmMa",
	"itemsPlaceholder": "ATiGgaXhCShzK8ycx4w2"
};
export default ___CSS_LOADER_EXPORT___;
